<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item>
        <el-button type="primary" @click="addPage">新增</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #handler="{ row }">
        <el-button type="primary" @click="toDetail(row)">详情</el-button>
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "机构", value: "param_key" },
        { name: "时间", value: "update_time" },
        { name: "说明", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "",
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    addPage() {
      this.$router.push({ name: "agentD" });
    },
    toDetail(row) {
      this.$router.push({ name: "agentD", query: { id: row.param_key } });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/sysParam/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          type: "data",
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>